import { MessageRenderMessage, NAlert } from "naive-ui";
import { h } from "vue";
import popupMessageDuration from "@/constants/popupMessageDuration";

export default () => {
  const handleError = (err: {
    response: { data: { message: string; error: { message: string | [] } } };
  }) => {
    const errorMessage = err.response.data.error.message;
    if (
      err.response.data.message.startsWith("Validation failed") &&
      typeof errorMessage === "string"
    ) {
      window.$message.error(errorMessage, {
        closable: true,
        render: (props) => {
          return renderMessage(props);
        },
        duration: popupMessageDuration,
      });
    } else {
      window.$message.error(err.response.data.message, {
        closable: true,
        duration: popupMessageDuration,
      });
    }
  };

  const renderMessage: MessageRenderMessage = (
    props,
    title = "Validation failed:"
  ) => {
    const { type } = props;
    return h(
      NAlert,
      {
        closable: props.closable,
        onClose: props.onClose,
        type: type === "loading" ? "default" : type,
        title,
        class: "custom-data-toasted",
      },
      {
        default: () => props.content,
      }
    );
  };
  return {
    handleError,
    renderMessage,
  };
};
